import React, { useEffect, useState } from "react";
import { BrowserRouter as Router, Switch, Route } from "react-router-dom";
import Contact from "./Components/Contact/Contact";
import GlobalStyles from "./Components/GlobalStyles";
import Loader from "./Components/Loader";
import Home from "./Pages";

const App = () => {
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    setLoading(true);
    setTimeout(() => {
      setLoading(false);
    }, 2000);
  }, []);

  return (
    <Router>
      <GlobalStyles />
      {loading ? (
        <Loader />
      ) : (
        <>
          <Switch>
            <Route path="/" exact component={Home} />
            <Route path="/contact" exact component={Contact} />
          </Switch>
        </>
      )}
    </Router>
  );
};

export default App;
