import React from "react";
import Styled from "styled-components";
import footlogo from "./images/footerLogo.svg";

const Footer = () => {
  return (
    <>
      <FooterStyle>
        <FooterSection>
          <div>
            <h3>Quick Links</h3>
            <div>
              <p>
                <a href="https://pbgdigital.co.uk/">
                  Prudent Digital International
                </a>
              </p>
              <p>
                <a href="https://pbgcare.co.uk/">Prudent Domiciliary Care</a>
              </p>
              <p>
                <a>Prudent Foundation</a>
              </p>
              <p>
                <a href="https://terrelldavies.com/">
                  Terrell Davies Enterprise
                </a>
              </p>
            </div>
          </div>

          <div>
            <h3>Our services</h3>
            <div>
              <p>
                <a href="https://pbgdigital.co.uk/services">
                  Digital Solutions
                </a>
              </p>
              <p>
                <a href="https://pbgcare.co.uk/our-services/">Care Services</a>
              </p>
              <p>
                <a href="https://terrelldavies.com/">
                  Real Estate Solutions
                </a>
              </p>
              <p>
                <a>Philanthropy</a>
              </p>
            </div>
          </div>

          <div>
            <h3>Contact us</h3>
            <div>
              <p>
                <a href="https://goo.gl/maps/udLvhnLfBe6XydNy9" id="shot">
                  G22 Prudent, Crown House, Home Gardens, Dartford - DA1 1DZ
                </a>
              </p>
              <p>
                <a href="tel:+441322686765">+44 132 2686 765</a>
                <a href="tel:+441322424495">+44 132 2424 495</a>
              </p>
              <p>
                <a href="mailto:info@prudentbusinessgroup.org">
                  info@prudentbusinessgroup.org
                </a>
              </p>
            </div>
          </div>
        </FooterSection>
        <Signature>
          <div>
            <img src={footlogo} alt="logo" />
          </div>
          <p>
            Copyright &copy;2020 Prudent Business Group. All Rights Reserved
          </p>
        </Signature>
      </FooterStyle>
    </>
  );
};

export default Footer;

const FooterStyle = Styled.div`
    background: #606060;
    width: 100%;
    height: auto;
    padding: 3% 6% 1.5%;
    color: #fff;

    @media screen and (min-height: 1050px) {
      height: 30vh;
  }
`;

const FooterSection = Styled.div`
    margin: auto;
    display: flex;
    align-items: center;
    justify-content: space-evenly;

    @media screen and (max-width: 789px) {
      display: block;
      text-align: left;
   }

    div {
      width: 100%;

      h3 {
        font-size: 1.5rem;
        margin-bottom: 1rem;
        
        @media screen and (max-width: 789px) {
          margin-top: 2.3rem;
        }
      }

      p {
        display: block;
        margin: 1rem 0;

        a {
          display: block;
          color: #fff;
          text-decoration: none;
          cursor: pointer;

          &:hover {
            transform: scale(1.05);
            transition-duration: 0.3s;
          }

        }
        #shot{
          margin-top: 0;
          width: 80%;
        }
      }
    }
`;

const Signature = Styled.div`
  text-align: center;
  margin-top: 2.5rem;

  p {
    margin-top: 0.8rem;
  }
  
`;
