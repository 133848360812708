import React, { useState } from "react";
import styled from "styled-components";
import axios from "axios";
import Footer from "../Footer/Footer";
import Header from "../Header/Header";
import { Link } from "react-router-dom";

const Contact = () => {
  const Swal = require("sweetalert2");
  const [name, setName] = useState("");
  const [company_name, setCompany_name] = useState("");
  const [phone_number, setPhone_number] = useState("");
  const [email, setEmail] = useState("");
  const [service, setService] = useState("PBG Digital");
  const [message, setMessage] = useState("");

  const onSubmit = (e) => {
    e.preventDefault();
    const data = {
      name,
      company_name,
      phone_number,
      email,
      service,
      message,
    };
    console.log(data);
    axios
      .post("https://api.pbgdigital.co.uk/api/pbg/contact-mail/", data)
      .then((res) => {
        console.log(res);
        Swal.fire({
          position: "center",
          icon: "success",
          title: "Messsage sent successfully",
          showConfirmButton: false,
          timer: 1500,
        });
      })

      .catch((err) => {
        console.log(err);
        Swal.fire({
          icon: "error",
          title: "Oops...",
          text: "Something went wrong!, please try again",
        });
      });
  };

  return (
    <>
      <Header />
      <ContactStyle>
        <div className="hero">
          <h1>Contact</h1>
          <div className="tag">
            <Link to="/">Home</Link>
            <div></div>
            <Link to="/contact">Contact</Link>
          </div>
        </div>

        <div className="head">
          <h2>Get in Touch</h2>
          <hr />
        </div>
        <div className="form_wrapper">
          <div className="text">
            <div className="cta">
              <h3>PRUDENT Business group</h3>
              <p>
                <a href="https://goo.gl/maps/udLvhnLfBe6XydNy9">
                  G22, Crown House, Home Gardens, Dartford DA1 1DZ
                </a>
              </p>
            </div>
            <div className="cta">
              <h3>Call Us</h3>
              <p>
                <a href="tel:+441322686765">+44 132 2686 765</a> <br />
                <a href="tel:+441322424495">+44 132 2424 495</a>
              </p>
            </div>
            <div className="cta">
              <h3>MAIL</h3>
              <p>
                <a href="mailto:info@prudentbusinessgroup.org">
                  info@prudentbusinessgroup.org
                </a>
              </p>
            </div>
          </div>
          <div className="form">
            <h3>Book an appointment Now</h3>
            <p>Let’s help you grow your business. Talk with us.</p>
            <form onSubmit={onSubmit}>
              <div className="input_group">
                <p>
                  Name <span>*</span>
                </p>
                <input
                  value={name}
                  onChange={(e) => setName(e.target.value)}
                  type="text"
                />
              </div>
              <div className="input_group">
                <p>
                  Email <span>*</span>
                </p>
                <input
                  value={email}
                  onChange={(e) => setEmail(e.target.value)}
                  type="email"
                />
              </div>
              <div className="input_group">
                <p>Company Name</p>
                <input
                  value={company_name}
                  onChange={(e) => setCompany_name(e.target.value)}
                  type="text"
                />
              </div>
              <div className="input_group">
                <p>
                  Phone Number <span>*</span>
                </p>
                <input
                  value={phone_number}
                  onChange={(e) => setPhone_number(e.target.value)}
                  type="text"
                />
              </div>
              <div className="input_group">
                <p>
                  Select Service Interested in <span>*</span>
                </p>
                <select
                  value={service}
                  onChange={(e) => setService(e.target.value)}
                >
                  <option value="PBG Digital">PBG Digital</option>
                  <option value="PBG Care">PBG Care</option>
                  <option value="Terrell Davies">Terrell Davies</option>
                </select>
              </div>
              <div className="input_group">
                <p>
                  Message <span>*</span>
                </p>
                <textarea
                  value={message}
                  onChange={(e) => setMessage(e.target.value)}
                  cols="30"
                  rows="10"
                ></textarea>
              </div>
              <button type="submit">Shoot</button>
            </form>
          </div>
        </div>
      </ContactStyle>
      <Footer />
    </>
  );
};

export default Contact;

const ContactStyle = styled.div`
  width: 100%;
  margin: 3rem auto 2rem;

  .hero {
    margin-top: -35px;
    text-align: center;
    padding: 2rem;
    color: #f5f5f5;
    margin-bottom: 2.6rem;
    background: linear-gradient(90deg, #260633 30%, #d90319 100%);

    h1 {
      font-size: 5.5rem;
      padding-top: 10px;
    }

    .tag {
      display: flex;
      justify-content: center;

      div {
        border-left: 1px solid #f5f5f5;
        height: 20px;
        margin-top: 8px;
      }

      a {
        font-size: 15px;
        padding: 10px;
        padding-bottom: 30px;
        color: #f5f5f5;
      }
    }
  }

  .head {
    h2 {
      color: #333333;
      text-align: center;
    }

    hr {
      width: 150px;
      margin: 1rem auto;
      border: none;
      border-top: 5px solid #ff5752;
    }
  }

  .form_wrapper {
    width: 65%;
    margin: 3rem auto;
    display: flex;
    /* align-items: center; */
    justify-content: space-between;

    @media screen and (max-width: 789px) {
      width: 90%;
      display: block;
    }

    .text {
      width: 40%;
      @media screen and (max-width: 789px) {
        width: 100%;
      }

      .cta {
        margin-bottom: 3rem;
      }

      h3 {
        color: #85868c;
        text-transform: uppercase;
        margin: 1rem auto;
      }

      p {
        width: 50%;
      }

      a {
        color: inherit;

        &:hover {
          text-decoration: underline;
        }
      }
    }

    .form {
      width: 50%;
      @media screen and (max-width: 789px) {
        width: 100%;
      }
      h3 {
        color: #85868c;
        font-size: 1.6rem;
      }

      span {
        color: #ff5752;
      }

      form {
        width: 100%;
        margin-top: 2rem;
        .input_group {
          margin: 1rem 0;

          p {
            color: #85868c;
            font-weight: bold;
            margin-bottom: 0.5rem;
          }

          input,
          select,
          textarea {
            width: 100%;
            padding: 0.7rem;
            outline: none;
            border: 1px solid #85868c;
            background: #fff;
            font-size: 1rem;
            color: #333333;
          }
        }

        button {
          width: 100px;
          height: 35px;
          text-align: center;
          padding: 0.3rem;
          border: none;
          background: #ff5752;
          color: #fff;
          transition: opacity 0.5s ease;

          &:hover {
            opacity: 0.6;
          }
        }
      }
    }
  }
`;
