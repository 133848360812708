import React, { useEffect, useState } from "react";
import Styled from "styled-components";
import { motion } from "framer-motion";
import Instagram from "../Images/instagram.svg";
// import { useInView } from "react-intersection-observer";
import { useMediaQuery } from "react-responsive";

const Card = ({ background, logo, title, paragraph, buttonUrl, igUrl }) => {
  const [showHidden, setShowHidden] = useState(false);
  const mobileScreens = useMediaQuery({ query: "(max-width: 789px)" });
  // const { ref, inView } = useInView({
  //   threshold: 0.2,
  // });

  useEffect(() => {
    mobileScreens && setShowHidden(true);
  }, [mobileScreens]);

  return (
    <CardStyle
      background={background}
      onMouseEnter={() => setShowHidden(true)}
      onMouseLeave={() => setShowHidden(false)}
      // ref={ref}
    >
      <motion.div
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: 2.4,
        }}
      >
        <motion.img
          animate={
            showHidden
              ? {
                  opacity: 1,
                  transition: { duration: 1.5 },
                }
              : {
                  opacity: 0,
                  transition: { duration: 1 },
                }
          }
          initial={showHidden ? { opacity: 0 } : { opacity: 1 }}
          src={logo}
          alt="Logo"
        />
        <motion.h2
          initial={{
            opacity: 0,
            y: "50px",
          }}
          animate={{
            opacity: 1,
            y: "0",
          }}
          transition={{
            duration: "1.2",
            delay: 2,
          }}
        >
          {title}
        </motion.h2>
        <motion.p
          animate={
            showHidden
              ? {
                  opacity: 1,
                  transition: { duration: 1.5 },
                }
              : {
                  opacity: 0,
                  transition: { duration: 1 },
                }
          }
          initial={showHidden ? { opacity: 0 } : { opacity: 1 }}
        >
          {paragraph}
        </motion.p>
        <motion.a
          href={buttonUrl}
          initial={{
            opacity: 0,
          }}
          animate={{
            opacity: 1,
          }}
          transition={{
            duration: "1",
            delay: 3,
          }}
        >
          Explore
        </motion.a>
      </motion.div>
      <motion.a
        href={igUrl}
        id="ig"
        initial={{
          opacity: 0,
        }}
        animate={{
          opacity: 1,
        }}
        transition={{
          duration: "1",
          delay: 2.3,
        }}
      >
        <img src={Instagram} alt="instagram" />
      </motion.a>
    </CardStyle>
  );
};

const CardStyle = Styled.div`
  min-width: 33.33%;
  height: 100%;
  background: linear-gradient(rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url(${(
    props
  ) => props.background});
  background-repeat: no-repeat;
  background-size: cover;
  background-position: center;
  padding: 6rem 2.5rem;
  color: #fff;
  /* z-index: -1; */
  cursor: pointer;
  position: relative;

  @media screen and (max-width: 789px) {
      width: 100%;
  }
  div {
    img {
        width: 165px;
        height: 76.5px;
    }
    h2 {
        width: 90%;
        font-size: 2rem;
        line-height: 41px;
        margin: 2rem 0 1rem;
        font-weight: 900;
    }
    p {
        font-size: 1.1rem;
        line-height: 23px;
        margin: 1.2rem 0;
    }
    a {
        display: block;
        width: 160px;
        height: 45px;
        padding: 0.6rem 1.5rem;
        background: transparent;
        color: #fff;
        border: 1px solid #fff;
        font-size: 1.1rem;
        font-weight: bold;
        margin-top: 1.2rem;
        transition: background 1s;
        text-align: center;
        &:hover {
            background: #fff;
            color: #606060;
        }

        &#position {
          position: absolute;
        }
    }
  }

  #ig {
    position: absolute;
    right: 3%;
    bottom: 2%;
    transition: all 0.4s ease-in-out;
    
    &:hover {
      transform: scale(1.1);
    }

  }
`;

export default Card;
