import { createGlobalStyle } from "styled-components";

const GlobalStyles = createGlobalStyle`
    * {
        margin: 0;
        padding: 0;
        box-sizing: border-box;
        font-family: 'Roboto', sans-serif;  
    }

    body {
        overflow-x: hidden;
    }

    h1 {
        font-size: 3.5rem;
        font-weight: 900;
        text-transform: capitalize;
    }

    h2 {
        font-size: 2.8rem;
        font-weight: 900;
        text-transform: capitalize;
    }

    a {
        text-decoration: none;
    }

    input, button {
        outline: none
    }

    button {
        cursor: pointer
    }
`;

export default GlobalStyles;
