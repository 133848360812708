import { AnimatePresence, motion } from "framer-motion";
import React from "react";
import Body from "../Components/Body/Body";
import Footer from "../Components/Footer/Footer";
import Header from "../Components/Header/Header";
const Home = () => {
  return (
    <>
      <AnimatePresence>
        <motion.div
          initial={{
            opacity: 0,
            visibility: "hidden",
          }}
          animate={{
            opacity: 1,
            visibility: "visible",
          }}
          exit={{
            opacity: 0,
            visibility: "hidden",
          }}
          transition={{
            duration: 1.3,
            delayChildren: 1.5,
          }}
        >
          <Header />
          <Body />
          <Footer />
        </motion.div>
      </AnimatePresence>
    </>
  );
};

export default Home;
